.container {
  height: 95%;
  .buttons {
    height: 100%;
    button {
      height: 100%;
      width: fit-content;
      min-width: none;
      padding: 4px;
    }
  }
}
